import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SVGIcon from "../components/Global/SVGIcon"
import Container from "../components/Global/Container"
import MarkdownText from "../components/Markdown/MarkdownText"

const CaseDetail = ({ data, pageContext }) => {
  const {
    name,
    client,
    types,
    images,
    description,
    link,
  } = data.contentfulProject

  return (
    <Layout>
      <SEO
        title={`${client}`}
        keywords={[`Showcase`, ...types]}
        description={description ? description.childMarkdownRemark.excerpt : ''}
        image={images[0].fluid}
      />

      <Container>
        <div className="post-row ">
          {types.map((t) => (
            <p key={t} className="post-category">
              {t}
            </p>
          ))}
        </div>
        <h1>{client}</h1>
        <p className="post-date">{name}</p>

        {description && <MarkdownText html={description.childMarkdownRemark.html} />}
        <Img fluid={images[0].fluid} className="blog-post-hero" />

        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <p className="post-date">{link}</p>
          </a>
        )}

        <div className="project-nav-row">
          <Link to={`/showcase/${pageContext.prevLink}`}>
            <SVGIcon name="arrow-left" width={30} />
          </Link>
          <Link to={`/showcase/${pageContext.nextLink}`}>
            <SVGIcon name="arrow-right" width={30} />
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($name: String) {
    contentfulProject(name: { eq: $name }) {
      name
      types
      client
      images {
        fluid(maxWidth: 1000) {
          src
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 250)
        }
      }
      link
    }
  }
`

export default CaseDetail
