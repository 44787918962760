import React from "react"
import "./markdown.scss"

const MarkdownText = (props) => {
  return (
    <div className="md-page" dangerouslySetInnerHTML={{ __html: props.html }} />
  )
}

export default MarkdownText
